/*------------------------------------------------------------------
FORMS STYLESHEET

Last change: [Initial Release]

Chanelog:
-------------------------------------------------------------------*/

// Form Controls

.form-control-md {
    height: calc(2.6rem + 2px);
}

textarea.no-resize {
    resize: none;
}

.form-row .input-group {
    align-items: center;
}

.form-group {
    label {
        font-size: $font-size-sm;
    }
}

// Custom Controls

.custom-control {
    label {
        cursor: pointer;
    }
}

.form-control {
    font-size: $font-size-sm;
}


// Custom Radio

.custom-radio {
    line-height: 1.3125rem;
    min-height: 1.3125rem;
}


.media-label {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}



.custom-select { background: none; }