// Theme Variables


$navigation-width:          4.375rem;
$sidebar-width:             26.25rem;
$chat-info-width:           21.875rem;


$message-unread:            #f4f4ff;
$btn-minimal-color:         rgba(33, 37, 41, .35);

$bg-left-message:           #f5f6fa;
$color-left-message:        #8094ae;

$bg-right-message:          $primary;
$color-right-message:       $white;