
// Hide scrollbar mixin
@mixin hide-scrollbar() {

    & {
        -ms-overflow-style: none;
        scrollbar-width: none;

        overflow-y: auto;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar {
        display: none;
    }

}