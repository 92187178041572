/*------------------------------------------------------------------
ROOT STYLESHEET

Last change: [Initial Release]

Chanelog:
-------------------------------------------------------------------*/

html,
body {
    height: 100%;
}