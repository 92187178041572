/*------------------------------------------------------------------
CHATLIST STYLESHEET

Last change: [Initial Release]

Chanelog:
-------------------------------------------------------------------*/

// Contact List Style
.contacts-list{
    padding: 0.75rem;
    list-style: none;

    .contacts-item{
        margin: 0.4rem 0;
        max-width: 100%;
        width: 100%;
        cursor: pointer;
        position: relative;

        @include media-breakpoint-up(sm){
            margin: 0.75rem 0;
        }

        &.active{
            .avatar::before {
                border-color: $primary;
            }
        }
        &.unread{
            .avatar::before {
                border-color: $message-unread;
            }
        }

        .contacts-link{
            display: flex;
            align-items: center;
            flex-grow: 1;
            max-width: 100%;
            padding: .8rem .5rem;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            transition: all .3s ease;
            color: inherit;

            &:hover{
                text-decoration: none;
            }
            

            @include media-breakpoint-up(sm){
                padding: 1rem 1.25rem;
            }
        }

        &:hover{

            @include media-breakpoint-up(xl){
                .contacts-link{
                    border-color: $primary;
                }
            }
        }

        &.active{

            @include media-breakpoint-up(xl){
                .contacts-link{
                    background: $primary;
                    border: 1px solid $primary;
                    
                    .chat-name{
                        color: $white;
                    }

                    .chat-time,
                    .contacts-texts,
                    svg,
                    p{
                        color: $gray-50 !important;
                    }
                }
            }

            
        }

        &.unread{
            
            .contacts-link{
                background: $message-unread;

                .contacts-texts{
                    color: $black;
                }
            }

            &.active{
                @include media-breakpoint-up(xl){
                    .contacts-link{
                        background: $primary;
                        border: 1px solid $primary;
                        
                        .chat-name,
                        .chat-time,
                        .contacts-texts{
                            color: $gray-50;
                        }
                    }
                }
            }
         
        }
    }

    .contacts-content{
        padding-left: 0.875rem;
        transition: all .4s;
        overflow: hidden;
        max-width: 100%;
        flex: 1 1 auto;

        .contacts-info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.375rem;

            .chat-name{
                font-size: $font-size-sm;
                margin-bottom: 0;
                margin-right: auto;
            }

            .chat-time{
                font-size: $font-size-sm;
                display: flex;
                align-items: center;
                color: $gray-500;
                white-space: nowrap;
            }
        }

        .contacts-texts{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $gray-500;

            p{
                
                font-size: $font-size-sm;
                line-height: 1.34;
                margin-bottom: 0;
                margin-right: auto;
            }
        }
    }
}