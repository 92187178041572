$grey: #545A5C;
$lightgrey: #CBD1D2;
$blue: #6691CC;
$red: #D61643;
$green: #8BBF43;

#event-calender{
    // height: 100%;
    
    @include media-breakpoint-up(xl) {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
}
.calendar {
  position: relative;
  overflow: hidden;
  text-transform: capitalize;
  text-align: center;
  color: $grey;
  padding: 2rem 1rem;
  background-color: $white;
  height: 100%;

  a {
    text-decoration: none;
    color: inherit;
  }

  header {
    .btn {
      display: inline-block;
      position: absolute;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: $lightgrey;
      border-radius: 50%;
      border: 1px solid $lightgrey;
    }

    .btn:hover {
      background: $lightgrey;
      color: white;
    }

    .btn:before {
      content: '';
      position: absolute;
      top: 9px;
      left: 8px;
      width: 8px;
      height: 8px;
      border-style: solid;
      border-width: 3px 3px 0 0;
      transform: rotate(45deg);
      transform-origin: center center;
    }

    .btn-prev {
      top: 0;
      left: 0;
      transform: rotate(-180deg);
    }

    .btn-next {
      top: 0;
      right: 0;

      &:before {
        transform: rotate(45deg);
      }
    }

    .month {
      padding: 0;
      margin: 0;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;

      .year {
        font-size: 14px;
        font-weight: 100;
      }
    }
  }

  table {
    width: 100%;
    margin: 20px 0;
    border-spacing: 0px;
  }

  thead {
    font-weight: 500;
  }

  td {
    padding: .8rem .1rem;
  }

  .day {
    position: relative;
    display: inline-block;
    // width: 1rem;
    // height: 1rem;
    padding: 8px;
    line-height: 1rem;
    border-radius: $border-radius;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
    //   border: 1px solid $primary;
      background-color: $lightgrey;
    }

    &.today {
      background: $primary;
      color: white;

      &.has-event:after {
        background: white;
      }
    }

    &.wrong-month {
      color: $lightgrey;
    }

    &.wrong-month:hover {
      border: 1px solid transparent;
    }

    &.has-event {
      &:after {
        content: '';
        position: absolute;
        top: calc(50% + .6em);
        left: calc(50% - 2px);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $blue;
      }
    }

    &.disabled{
      cursor: default;

      &:hover {
        border: 1px solid transparent;
      }
    }
  }

  .event-container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 70px;
    background: $grey;
    box-sizing: border-box;

    .event-wrapper{
      overflow-y: auto;
      max-height: 100%;
    }

    .close{
      position: absolute;
      width:30px;
      height: 30px;
      top: 20px;
      right:20px;
      cursor: pointer;

      &:before, &:after {
        content: '';
        position: absolute;
        top:0;
        left: 50%;
        width: 2px;
        height: 100%;
        background-color: $lightgrey;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }

    .event{
      position: relative;
      width:100%;
      padding: 1em;
      margin-bottom: 1em;
      background: $primary;
      border-radius: 4px;
      box-sizing: border-box;
      box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12);
      text-align: left;
      color:white;

      &-date{
        margin-bottom: 1em;
      }

      &-hour{
        float:right;
      }

      &-summary{
        font-weight: 600;
      }
    }
  }

  .filler {
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: $grey;
    transform: translate(-50%, -50%);
  }
}
