/*------------------------------------------------------------------
SIDEBAR STYLESHEET

Last change: [Initial Release]

Chanelog:
-------------------------------------------------------------------*/
.sidebar-header {
  background: $white;
  border-bottom: 1px solid $border-color;
}

.sidebar-sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: .25rem 0;
}


