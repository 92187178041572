/*------------------------------------------------------------------
CHAT STYLESHEET

Last change: [Initial Release]

Chanelog:
-------------------------------------------------------------------*/
.chat-body {
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    min-width: 0;
    width: 100%;

    .chat-header {
        padding: .75rem 1rem;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            padding: .75rem 2.25rem;
        }
    }

    .chat-content {
        @include hide-scrollbar();
        flex: 2;
    }

    .chat-footer {
        // padding: .75rem 1rem;
        // border-top: 1px solid $border-color;

        // @include media-breakpoint-up(md) {
        //     padding: .75rem 2.25rem;
        // }

        // textarea {
        //     font-size: 0.75rem;

        //     @include media-breakpoint-up(sm) {
        //         font-size: $font-size-sm;
        //     }
        // }

        position: relative;

        .form-control {
            border: 0;
            border-top: 1px solid $border-color;
            border-radius: 0;
            
        }

        &>textarea {
            padding: 1.5rem 1rem 1.5rem 4rem;
        }

        .send-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            height: 3rem;
            width: 3rem;
            padding: 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .attachment {
            position: absolute;
            left: 0.5rem;
            top: 1.25rem;
            z-index: 1;

            @include  media-breakpoint-up(sm){
                left: 0.75rem;
                top: 0.75rem;
            }
        } 

    }
}

.chat-info {
    @include hide-scrollbar();
    height: 100vh;
    height: 100%;
    background: $white;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 100%;
    z-index: 1025;
    width: 100%;
    visibility: hidden;
    transition: transform .3s ease, visibility .3s ease ;


    @include media-breakpoint-up(xl) {
        position: static;
        border-left: 1px solid $border-color;
        visibility: hidden;
        width: 0;
        // margin-right: -1px;
        height: 100vh;
    }

    &.chat-info-visible {
        visibility: visible;
        transform: translateX(-100%);
        z-index: 1030;

        @include media-breakpoint-up(xl) {
            transform: translateX(0);
            visibility: visible;
            min-width: $chat-info-width;
            width: $chat-info-width;
            
        }

        @media screen and (max-width: 1300px) and (min-width: 1200px) {
            position: fixed;
            top: 0px;
            bottom: 0px;
            right: 0px;
            transform: translateX(-100%);
            border-left: 1px solid $border-color;
        }
    }

    .chat-info-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid $border-color;
        min-height: 4.5625rem;
    }
    .chat-info-group{
        border-top: 1px solid $border-color;
        padding: .25rem 0;

        .chat-info-group-header{
            padding: 1.25rem 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: inherit;

            @include media-breakpoint-up(lg) {
                padding-right: 1.75rem;
                padding-left: 1.75rem;
            }
          
        }

        .chat-info-group-content{
            padding: 0 1.5rem 1.5rem;

            @include media-breakpoint-up(lg) {
                padding-right: 1.75rem;
                padding-left: 1.75rem;
            }

            &.list-item-has-padding{
                padding-left: 0;
                padding-right: 0;

                .list-group-item{
                    padding: 0 1.5rem 1.5rem;
                    padding: .75rem 1.25rem;

                    @include media-breakpoint-up(lg) {
                        padding-right: 1.75rem;
                        padding-left: 1.75rem;
                    }

                    &:first-child{
                        padding-top: 0;
                    }

                    &:last-child{
                        padding-bottom: 0;
                    }
                }
            }

            
        }
    }
}

.chat-closer {
    position: absolute;
    top: 0;
    left: 0.625rem;
    display: block;
}

.emoji-picker__emojis{
    @include hide-scrollbar();
}