/*------------------------------------------------------------------
CARDS STYLESHEET

Last change: [Initial Release]

Chanelog:
-------------------------------------------------------------------*/

.card{
    position: relative;

    .card-options{
        position: absolute;
        top: 0;
        right: 0.625rem;
        display: block;
    }
}